$(function() {


	(function ($) {
		"use strict";
		$(function () {
			var header = $(".start-style");
			$(window).scroll(function () {
				var scroll = $(window).scrollTop();
				if (scroll >= 10) {
					header.removeClass('start-style').addClass("scroll-on");
				} else {
					header.removeClass("scroll-on").addClass('start-style');
				}
			});
		});

		$(document).ready(function () {
			$('body.hero-anime').removeClass('hero-anime');
		});

		$('body').on('mouseenter mouseleave', '.nav-item', function (e) {
			if ($(window).width() > 750) {
				var _d = $(e.target).closest('.nav-item');
				_d.addClass('show');
				setTimeout(function () {
					_d[_d.is(':hover') ? 'addClass' : 'removeClass']('show');
				}, 1);
			}
		});


		$(document).ready(function () {
			console.clear();
			var target = '.content-with-sidebar__content__wrapper .item-company',
				size = $(target).length,
				x = 4,
				addTo = 4,
				loadButton = $('.load-more');
			$(target).hide();
			$(target + ':lt(' + x + ')').show();
			loadButton.on("click", function () {
				x = (x + addTo <= size) ? x + addTo : size;
				$(target + ':lt(' + x + ')').show();
				if (x == size) {
					$(this).hide();
				}
				return false;
			});
		});


		$('.top-company-logotypes__wrapper').slick({
			slidesToShow: 6,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 2000,
			arrows: false,
			responsive: [
				{
					breakpoint: 992,
					settings: {

						slidesToShow: 4
					}
				},
				{
					breakpoint: 768,
					settings: {

						slidesToShow: 2,
						dots: true
					}
				}
			]
		});


	})(jQuery);



	$(".preview").click(function () {
		$("#upload").click();

	});

	$("#upload").change(function () {
		preview(this);
	});
	function preview(input) {
		if (input.files && input.files[0]) {
			var reader = new FileReader();
			reader.onload = function (e) {
				$('.preview').attr('src', e.target.result);
			};
			reader.readAsDataURL(input.files[0]);
		}
	}

});